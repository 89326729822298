import ExerciseBookDetails from '@/components/ExerciseBookDetails/ExerciseBookDetails.vue'
export default {
    components: {
        ExerciseBookDetails
    },
    data() {
        return {}
    },
    mounted() {

    },
    methods: {

    },
}